import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import NewsPosts from "../components/templates/news/NewsPosts"

const News = props => {
  const { newsPosts, seoInfo } = props.data
  const newsPostsData = newsPosts.template.templatePageNews

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <NewsPosts data={newsPostsData} />
    </Layout>
  )
}

export const newsTempQuery = graphql`
  query newsTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    newsPosts: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_News {
          templatePageNews {
            displayNewsPosts
          }
        }
      }
    }
  }
`

export default News

import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import BackgroundOne from "../../Icons/BackgroundOne"
import smileImg from "../../../images/smile.png"

import {
  B1Blue,
  colors,
  H1White,
  B2LightBlue,
  B2Blue,
  H2Blue,
  medWrapper,
} from "../../../styles/helpers"

const getData = graphql`
  {
    posts: allWpPost {
      edges {
        node {
          postTypePost {
            excerpt
            featuredImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
          date
          title
          slug
          id
        }
      }
    }
  }
`

const NewsPosts = ({ data }) => {
  const postsData = useStaticQuery(getData)
  const posts = postsData.posts.edges

  if (!data.displayNewsPosts)
    return (
      <SectionStyled>
        <div className="wrapper">
          <div className="title">
            <h1>Stay up to date on community news</h1>
          </div>
          <div className="soon">
            <p>News section coming soon!</p>
          </div>
        </div>
      </SectionStyled>
    )

  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="title">
          <h1>Stay up to date on community news</h1>
        </div>
        <div className="posts">
          {posts.map(post => {
            const options = { year: "numeric", month: "long", day: "numeric" }
            const postDate = new Date(post.node.date).toLocaleDateString(
              undefined,
              options
            )
            const imageDisplay = getImage(
              post.node.postTypePost.featuredImage.localFile.childImageSharp
                .gatsbyImageData
            )
            const imageAlt = post.node.postTypePost.featuredImage.altText
            return (
              <PostStyled to={`/news/${post.node.slug}`} key={post.node.id}>
                <div className="post-image">
                  <GatsbyImage
                    image={imageDisplay}
                    alt={imageAlt}
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div className="post-title">
                  <h2>{post.node.title}</h2>
                </div>
                <div className="post-excerpt">
                  <p>{post.node.postTypePost.excerpt}</p>
                </div>
                <div className="post-date">
                  <p>{postDate}</p>
                </div>
              </PostStyled>
            )
          })}
        </div>
        <div className="load-more-btn">
          <button>
            <span className="btn-wrap">
              <span className="btn-img">
                <img src={smileImg} alt="Load More" />
              </span>
              <span className="btn-text">Load More Articles</span>
            </span>
          </button>
        </div>
      </div>
      <div className="bg-one">
        <BackgroundOne />
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  position: relative;

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    margin: 3rem auto;
    text-align: center;

    h1 {
      ${H1White};
      display: inline-block;
      padding: 0 2rem;
      background-color: ${colors.colorPrimary};
    }
  }

  .soon {
    width: 100%;
    margin: 15rem auto;
    text-align: center;

    p {
      ${H2Blue};
    }
  }

  .posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .load-more-btn {
    width: 100%;
    margin-top: 5rem;
    text-align: center;

    button {
      ${B1Blue};

      border: none;
      background-color: transparent;
      font-weight: bold;
      cursor: pointer;

      .btn-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btn-img {
        display: inline-block;
        max-width: 3.5rem;
        margin-right: 1rem;
      }
    }
  }

  .bg-one {
    position: absolute;
    bottom: -25rem;
    left: -30rem;
    max-width: 40rem;
    margin: auto;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -25rem;
      left: -30rem;
    }

    @media (min-width: 1025px) {
      bottom: -15rem;
      left: -10rem;
    }
  }
`

const PostStyled = styled(Link)`
  width: 100%;
  background-color: #fff;
  border-bottom: 2rem solid ${colors.colorPrimary};

  @media (min-width: 768px) {
    width: calc(50% - 2rem);
    margin: 2rem 1rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.333333% - 4rem);
    margin: 3rem 2rem;
  }

  .post-title {
    width: 100%;
    padding: 0 2rem;

    h2 {
      ${B1Blue};
    }
  }

  .post-excerpt {
    width: 100%;
    padding: 0 2rem;

    p {
      ${B2LightBlue};
    }
  }

  .post-date {
    width: 100%;
    padding: 0 2rem;

    p {
      ${B2Blue};
      font-weight: bold;
    }
  }
`

export default NewsPosts
